// extracted by mini-css-extract-plugin
export var banner_aboutus = "about-module--banner_aboutus--VfbCL";
export var paddingTop100 = "about-module--paddingTop100--lmzOG";
export var bold = "about-module--bold--6LEnq";
export var fontWhite = "about-module--fontWhite--3dzIt";
export var bannerHeading_comingSoon = "about-module--bannerHeading_comingSoon--edyyg";
export var bannerHeading = "about-module--bannerHeading--yL8Fn";
export var heightFull = "about-module--heightFull--w5Zb+";
export var TextJustify = "about-module--TextJustify--1Iyzj";
export var lineHeight30 = "about-module--lineHeight30--jw9Fb";
export var about_row_1 = "about-module--about_row_1--BhRRN";
export var idealogy_bg = "about-module--idealogy_bg--k6WV9";
export var idealogy = "about-module--idealogy--SYy7j";
export var TextLeftBold = "about-module--TextLeftBold--iT26p";
export var paddingTop60 = "about-module--paddingTop60--MddW8";
export var aboutHeading = "about-module--aboutHeading--JDsHj";
export var objectiveCards = "about-module--objectiveCards--u1CEG";
export var aboutTitle = "about-module--aboutTitle--t2Xer";
export var focus_row = "about-module--focus_row--RaDNb";
export var roadmap_bg = "about-module--roadmap_bg--lMoJ0";
export var roadmap = "about-module--roadmap--DJFsb";
export var roadmap_cards = "about-module--roadmap_cards--+QC5n";
export var roadmap_vision = "about-module--roadmap_vision--vpjHb";
export var paddingLeft20 = "about-module--paddingLeft20--Z4VI7";
export var TextCenterBoldWhiteSmall = "about-module--TextCenterBoldWhiteSmall---OQiM";
export var Contactuspage = "about-module--Contactuspage--iy-4F";
export var Technologies = "about-module--Technologies--Mx8Rv";
export var focus = "about-module--focus--XJEVx";
export var contactus = "about-module--contactus--Ti5fn";