import React from "react"
import { Link } from "gatsby"
import { Navbar, Nav, Row, Container, Col } from "react-bootstrap";
import logoSVG from "../assets/images/Logo.png";
import { animateScroll as scroll } from "react-scroll";
import * as Styles from "../style/home.module.scss";
import CallToAction from "./button/index";
const NavbarComponent = () => {
    return (
        <Navbar className="navbar-dark navbar-expand-sm"  expand="lg" >
            <Container>
                <Navbar.Brand  >
                    <Link to="/"> <img src={logoSVG} alt="Skillelevator Logo" className={Styles.logo}/></Link>
                </Navbar.Brand>
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                    <Nav className="ml-auto align-items-center">
                        <Nav.Link className={Styles.CustomNavLink} >
                            <Link
                                activeClass="active"
                                to="/"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Home
                            </Link>
                        </Nav.Link>
                        <Nav.Link className={Styles.CustomNavLink} >
                            <Link

                                to="/about-us/"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                About us
                            </Link>
                        </Nav.Link>
                        <Nav.Link className={Styles.CustomNavLink} >
                            <Link

                                to="/platform/"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Platform
                            </Link>
                        </Nav.Link>
                        <Nav.Link className={Styles.CustomNavLink} >
                            <Link

                                to="/why-skill-elevator/"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Why Skill-Elevator
                            </Link>
                        </Nav.Link>


                        <Nav.Link className={Styles.CustomNavLink} >
                            <Link

                                to="/contact"
                                spy={true}
                                smooth={true}
                                offset={-70}
                                duration={500}
                            >
                                Contact
                            </Link>
                        </Nav.Link>
                        <Nav.Link className={Styles.CustomNavLink} >
                            <CallToAction title="Signup for free" />
                        </Nav.Link>
                    </Nav>
                </Navbar.Collapse>
            </Container>
        </Navbar>

    )
}

export default NavbarComponent