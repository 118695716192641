import * as React from "react"
import { Col, Container, Row } from "react-bootstrap"
import About_us_Objective_Icon1 from "../assets/images/About_us_Objective_Icon1.png"
import About_us_Objective_Icon2 from "../assets/images/About_us_Objective_Icon2.png"
import About_us_Objective_Icon3 from "../assets/images/About_us_Objective_Icon3.png"
import About_us_Objective_Icon4 from "../assets/images/About_us_Objective_Icon4.png"
import About_us_Roadmap1 from "../assets/images/About_us_Roadmap1.png"
import About_us_Roadmap2 from "../assets/images/About_us_Roadmap2.png"
import About_us_Roadmap3 from "../assets/images/About_us_Roadmap3.png"
import About_us_Roadmap4 from "../assets/images/About_us_Roadmap4.png"
import Header from "../components/header_v1"
import Layout from "../components/layout"
import Seo from "../components/seo"
// import * as Styles from "../style/home.module.scss"
import * as Styles from "../style/about.module.scss";
import Contactus from "../components/Contactus"
import { navigate } from 'gatsby';
const AboutPage = () => {
  const comingsoon = () => {
    navigate("/coming-soon")
  }
  const why = [
    {
      imgUrl: About_us_Objective_Icon1,
      heading: "Simulating work experience in Accounting and Tax fields",
      details: ""
    },
    {
      imgUrl: About_us_Objective_Icon2,
      heading: "Online Platform to provide actual job experience",
      details: ""
    },
    {
      imgUrl: About_us_Objective_Icon3,
      heading: "Experiencing Actual Accounting works of Industry/Corporate",
      details: ""
    },
    {
      imgUrl: About_us_Objective_Icon4,
      heading: "Gain 3 years of strong experience in virtual company",
      details: ""
    },
  ]
  const my = [
    {
      imgUrl: About_us_Roadmap1,
      heading: " To offer simulated job experience to 1million commerce students in 3 years and help them to get faster and better job.",
      details: ""
    },
    {
      imgUrl: About_us_Roadmap2,
      heading: "To setup Business Lab in more than 10,000 colleges in 3 years.",
      details: ""
    },
    {
      imgUrl: About_us_Roadmap3,
      heading: "To enable at least 10,000 CA firms to equip themselves for training to their Article Students using our platforms.",
      details: ""
    },
    {
      imgUrl: About_us_Roadmap4,
      heading: " To onboard at least 1,000 corporates to use our platform for their fresher training program.",
      details: ""
    },
  ]
  return (
    <Layout>
      <Seo title="About US" />
      <div id="Home" className={Styles.banner_aboutus}>
        <Header />
        <hr style={{ border: "1px solid #FFFFFF", height: "0.005em", color: "#ffffff", backgroundColor: "#ffffff" }} />
        <Container fluid >
          <Row>
            <Col md={3}></Col>
            <Col md={6} className={`${Styles.paddingTop100}`}>
              <div>
                <h1 className={`${Styles.bold} ${Styles.fontWhite} ${Styles.bannerHeading}`}>
                  A Digital Platform
                  providing hands-on <br />finance job experience
                </h1>
                <div style={{ paddingTop: "10px" }}>
                  <span style={{ color: "#fff", backgroundColor: "#32A3EE", padding: "7px" }}>The best platform for commerce skilling & job enhancement</span>
                </div>
              </div>
            </Col>
            <Col md={3}></Col>
          </Row>
        </Container>
      </div>
      <div >
        <Container fluid>
          <Row className={`${Styles.heightFull} ${Styles.paddingBottom100} ${Styles.paddingTop100}`} >
            <Col md={1}></Col>
            <Col md={5} >
              <div className={`${Styles.TextLeftBold} ${Styles.paddingTop60}`}>
                About us
              </div>

              <div className={`${Styles.TextJustify} ${Styles.lineHeight30} `} >
                Skill Elevator is India's first web platform to provide complete Industry and Job experience in virtual environment in accounting and finance filed. Under this platform, all required accounting, tax, statutory filing and other business works have been created as a practical modules
                with actual bills, transactions, filings, procedural documents etc which are going to be used in while working in our modules. Skill elevator modules are not theory programmes but it is actual job works that are created in virtual companies where one has to work to gain actual job or industry experience. All modules are supported by theory and video tutor to facilitate the student understand the module in better way. The objective of Skill elevator is to enhance skill and employment for Commerce and Business students.
              </div>
            </Col>
            <Col md={5} className={Styles.about_row_1}></Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>

      <div className={Styles.idealogy_bg}>
        <Container fluid >
          <Row className={`${Styles.heightFull} ${Styles.paddingBottom100} ${Styles.paddingTop100}`} >
            <Col md={1}></Col>
            <Col md={5} className={Styles.idealogy}>
              <div  ></div>
            </Col>
            <Col md={5} >
              <div className={`${Styles.TextLeftBold}`} style={{ paddingLeft: "100px" }}>
                Ideology
              </div>
              <div className={Styles.TextJustify}className={Styles.Technologies} style={{ paddingLeft: "100px", paddingRight: "100px"}}>
                Skill-Elevator Technologies Pvt Ltd is founded on 16th August 2021 by team of Industry experts with the mission of skill and employability enhancement.  Prior to incorporation, the experts spent more than two years to conclude and draft the concept. These experts agreed to form an entity and become founders to run this project. It is a web based platform designed for college students and job  seeker.
                The platform is designed with advanced IT technology to make more interesting and easy for the user to work on it. This platform consists of various working modules that replicate the actual work experience in the Industry. In a way, it gives a virtual company where the college students and job seeker will login and learn the actual works or job related activities or company related transactions on virtual mode. This platform enable them to gain the work experience with out working in any Industry. It gives a feel to freshers as experienced.
              </div>
            </Col>
            <Col md={1}></Col>
          </Row>
        </Container>
      </div>
      <div id="Objective" className={`${Styles.paddingBottom100} ${Styles.paddingTop60}`} style={{ backgroundColor: "#EFEEEC" }}>
        <Container>
          <h2 className={Styles.aboutHeading} style={{ marginBottom: 40 }}>Objective</h2>
          <Row >
            <Col md={1} ></Col>
            <Col md={5} >
              <div className={Styles.objectiveCards}>
                <img src={why[0].imgUrl} alt="why" style={{ width: "100px", height: "100px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{why[0].heading}</span>
              </div>
            </Col>
            <Col md={5} >
              <div className={Styles.objectiveCards}>
                <img src={why[1].imgUrl} alt="why" style={{ width: "100px", height: "100px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{why[1].heading}</span>
              </div>
            </Col>
            <Col md={1} ></Col>
          </Row>
          <Row >
            <Col md={1} ></Col>
            <Col md={5} >
              <div className={Styles.objectiveCards}>
                <img src={why[2].imgUrl} alt="why" style={{ width: "100px", height: "100px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{why[2].heading}</span>
              </div>
            </Col>
            <Col md={5} >
              <div className={Styles.objectiveCards}>
                <img src={why[3].imgUrl} alt="why" style={{ width: "100px", height: "100px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{why[3].heading}</span>
              </div>
            </Col>
            <Col md={1} ></Col>
          </Row>
        </Container>
      </div>
      <div style={{ backgroundColor: "#fff", height: "600px" }}className={Styles.focus} >
        <Container fluid>
          <Row className={Styles.heightFull}  style={{ marginTop: "106px" }}>
            <Col md={1}></Col>
            <Col md={5} >
              <div className={`${Styles.TextLeftBold} ${Styles.paddingTop100}`}>
                Focus
              </div>
              <div className={Styles.TextJustify}>
                Providing learning opportunity to all commerce student and enable them to gain work experience while in their study or support them in seeking job faster. Work with colleges and offer them to setup 'Business Laboratories' to their commerce students. Work with CA firms and offer their Article Student get in to learning mode as a First Step to CA. Work with HRs in every Corporates to make our module as an Induction Training Platform to offer their new joiners as First Job Training. Work with Universities to Make this module as Skill Development in their curriculum. Work with Recruitment firms to consider our trained people in filling their vacancy needs .
              </div>
            </Col>
            <Col md={1}></Col>
            <Col md={5} className={Styles.focus_row}></Col>
          </Row>
        </Container>
      </div>
      <div className={`${Styles.roadmap_bg} ${Styles.paddingTop60}`}>
        <Container fluid >
          <h2 className={` ${Styles.aboutHeading} `}  >Roadmap</h2>
          <Row className={` ${Styles.paddingBottom100}  `} >
            <Col md={1}></Col>
            <Col md={5} className={Styles.roadmap}>
            </Col>
            <Col md={6} className={` ${Styles.paddingBottom100} `}>
              <div className={Styles.roadmap_cards}>
                <img src={my[0].imgUrl} alt="why" style={{ width: "50px", height: "50px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{my[0].heading}</span>
              </div>
              <div className={Styles.roadmap_cards} style={{ marginTop: "40px" }}>
                <img src={my[1].imgUrl} alt="why" style={{ width: "50px", height: "50px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{my[1].heading}</span>
                <p></p><p></p>
              </div>
              <div className={Styles.roadmap_cards}>
                <img src={my[2].imgUrl} alt="why" style={{ width: "50px", height: "50px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{my[2].heading}</span>
              </div>
              <div className={Styles.roadmap_cards} style={{ marginTop: "40px" }}>
                <img src={my[3].imgUrl} alt="why" style={{ width: "50px", height: "50px", verticalAlign: "top" }} />
                <span className={Styles.aboutTitle} style={{ display: "inline-block", width: "220px", minHeight: "100px", padding: "10px", }}>{my[3].heading}</span>
                <p></p><p></p>
              </div>
            </Col>
            <Col md={1} style={{ paddingTop: "20px" }} >
            </Col>
          </Row>
        </Container>
      </div>
      <div style={{ position: "relative", top: "-150px" }}>
        <Container fluid >
          <Row>
            <Col md={2}></Col>
            <Col md={8} className={Styles.roadmap_vision}>
              <div className={`${Styles.paddingTop60} ${Styles.paddingLeft20}`}>
                <span className={Styles.TextCenterBoldWhiteSmall}> Our Vision</span>
                <br />
                <span className={Styles.fontWhite}> “ To become India's Top Web platform in  Job simulation ”</span>
              </div>
            </Col>
            <Col md={2}></Col>
          </Row>
        </Container>
      </div>
      <div  className={Styles.Contactuspage}>
        <Container className={Styles.contactus}>
          <Contactus />
        </Container>
      </div>
    </Layout >
  )
}
export default AboutPage
